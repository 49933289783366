import React from 'react';
import preeti from "../assets/Preeti_Mam.png"
import "../Contact/contact.css";
import hari from "../assets/hari.png"
import vikas from "../assets/vikas.png";
import prabhu from "../assets/prabhu.png";
import vishal from "../assets/vishald.png"
import Rajesh from "../assets/Rajesh.png";
import Rakesh from "../assets/Rakesh.png";
import visahlL from "../assets/VishalL.png";
import Chirag from "../assets/Chirag.png";
import Brand from "../assets/brands.png";
import "./team.css"
import Footer from './Footer';
import sceniuz from "../assets/Sceniuz_Logo.png";
import Futura from "../assets/Futura_Hotels.png";
import P16 from "../assets/p16.png";
import Happy from "../assets/Happy_Hours.png"



const Team = () => {
    return (
        <>
            <div className="our-leadership-container-wrapper">
                <div className="home-page-container-data pt-0 pl-8 pr-8 pb-0">
                    <h1 className="contact-us-header mt-5 mb-5">Our Leadership</h1>
                    <figure className="hopme-page-data-wrapper md:flex bg-slate-100 rounded-xl p-18 md:p-0 dark:bg-slate-100 p-2 p-3 md:items-center">
                        <img className="image-data-home w-2/5 h-full md:w-58 md:h-auto md:rounded-none rounded-full mx-auto p-1 m-10" src={preeti} alt="" />
                        <div className="pt-6 md:p-8 text-center md:text-left space-y-4">
                            <figcaption className="font-medium justify-center">
                                <div className="name-section">
                                    PREETI MAHAPATRA
                                </div>
                                <div className="header-designantion-home">
                                    Founder & Mentor
                                </div>
                            </figcaption>
                            <blockquote>
                                <p className="home-page-para text-[15px] font-medium text-justify">
                                    Mrs. Preeti Mahapatra holds a master’s degree in International Business (Finance) and Export Management. She has spearheaded the successful launch of International Brands in the European, Asian, and Middle Eastern markets, showcasing her prowess in the global business arena from a remarkably young age. Besides extensive experience collaborating with global luxury brands, she possesses an acute understanding of the intricacies across various industry. Beyond her professional endeavors,  Mrs. Mahapatra is actively involved in philanthropy through her non-profit organization. By allocating a portion of her profits, she spearheads initiatives aimed at addressing key social issues such as education, shelter, disaster management, and more, spanning across various states of India.
                                </p>
                            </blockquote>
                        </div>
                    </figure>

                    <figure className="hopme-page-data-wrapper md:flex bg-slate-100 rounded-xl p-18 md:p-0 dark:bg-slate-100 p-2 p-3 mt-5 md:items-center">
                        <img className="image-data-home w-2/5 h-full md:w-58 md:h-auto md:rounded-none rounded-full mx-auto p-1 m-10" src={hari} alt="" />
                        <div className="pt-6 md:p-8 text-center md:text-left space-y-4">
                            <figcaption className="font-medium justify-center">
                                <div className="name-section">
                                    HARI MAHAPATRA
                                </div>
                                <div className="header-designantion-home">
                                    Managing Director
                                </div>
                            </figcaption>
                            <blockquote>
                                <p className="home-page-para text-[15px] font-medium text-justify">
                                    Hari Mahapatra is a seasoned professional with over 25+ years of experience in spearheading investments across various sectors, ranging from manufacturing to financial services. With a strong emphasis on organizational development and nurturing talent, he brings to the table a wealth of expertise. Holding an MBA degree, Mahapatra boasts a robust track record in navigating international and multicultural business landscapes. Mahapatra's achievements underscore his proficiency in achieving revenue, profit, and business expansion objectives across diverse operational scenarios, including start-ups, turnaround initiatives, and rapid-change environments. His extensive experience with complex engineered systems underscores his adeptness in understanding critical business drivers across multiple industries. Known for his ability to foster strong relationships with industry leaders, tackle pivotal challenges head-on, and uphold unwavering commitment to customer satisfaction. Mahapatra stands as a testament to excellence in leadership and strategic acumen.
                                </p>
                            </blockquote>
                        </div>
                    </figure>
                </div>



                <div className="home-page-container-data pt-0 pl-8 pr-8 pb-0 mt-4 mb-4">
                    <h1 className="contact-us-header">Management Team</h1>
                </div>

                <div className="our-team-container grid grid-cols-1 md:grid-cols-2 gap-5 pt-0 pl-8 pr-8 pb-0">
                    <div className="our-management-card-conatiner">
                        <div className="our-card-wrapper grid grid-cols-1 md:grid-cols-2 gap-3">
                            <div className="image-text-conatainer">
                                <img src={vikas} alt="" srcset="" className='image-our-management' />
                                <h3 className="management-name">VIKAS KAPAI</h3>
                                <p className="management-designation">CEO- Futura Hotels & Resorts</p>
                            </div>
                            <div className="management-description">
                                <p className="mamanemnet-description-content text-justify">Chief Executive Officer of Futura Hotels & Resorts, Mr. Kapai boasts a 30-year career in hospitality, including leadership roles at Hyatt, Leela, Hilton, Marriott, and Accor. A distinguished IHM-Pusa graduate, he excels in Hotel operations, P&L management, Brand Management, and Sales.</p>
                            </div>
                        </div>
                    </div>

                    <div className="our-management-card-conatiner">
                        <div className="our-card-wrapper grid grid-cols-1 md:grid-cols-2 gap-3">
                            <div className="image-text-conatainer">
                                <img src={prabhu} alt="" srcset="" className='image-our-management' />
                                <h3 className="management-name">PRABHUDAS NAMBIAR</h3>
                                <p className="management-designation">Director</p>
                            </div>
                            <div className="management-description">
                                <p className="mamanemnet-description-content text-justify">A Masters degree in Accounting & Finance and with a global experience over 25 years in finance, Mr. Nambiar has served the Mahapatra Group from it's incorporation. Under the directives of Mr. Nambiar, the group has restructured it's strategies on financial.</p>
                            </div>
                        </div>
                    </div>


                    <div className="our-management-card-conatiner">
                        <div className="our-card-wrapper grid grid-cols-1 md:grid-cols-2 gap-3">
                            <div className="image-text-conatainer">
                                <img src={vishal} alt="" srcset="" className='image-our-management' />
                                <h3 className="management-name">VISHAL DHURE</h3>
                                <p className="management-designation">CEO –  Sceniuz </p>
                            </div>
                            <div className="management-description">
                                <p className="mamanemnet-description-content text-justify">Chief Executive Officer of Sceniuz, the IT division of Mahapatra Universal Limited, Mr. Dhure has over 20 years of expertise in data analytics and software development. He drives profitability and manages operations, with deep experience in HR, Admin, IT, and Sales.</p>
                            </div>
                        </div>
                    </div>



                    <div className="our-management-card-conatiner">
                        <div className="our-card-wrapper grid grid-cols-1 md:grid-cols-2 gap-3">
                            <div className="image-text-conatainer">
                                <img src={Rajesh} alt="" srcset="" className='image-our-management' />
                                <h3 className="management-name">RAJESH GROVER</h3>
                                <p className="management-designation">CEO- International Operations</p>
                            </div>
                            <div className="management-description">
                                <p className="mamanemnet-description-content text-justify">A BS in Accounting from Saint John’s University, Mr. Grover has extensive experience in financial and capital markets. He co-founded a successful medical company, raising over $90M for various projects and managing significant relationships with physicians and investors.</p>
                            </div>
                        </div>
                    </div>



                    <div className="our-management-card-conatiner">
                        <div className="our-card-wrapper grid grid-cols-1 md:grid-cols-2 gap-3">
                            <div className="image-text-conatainer">
                                <img src={Rakesh} alt="" srcset="" className='image-our-management' />
                                <h3 className="management-name">RAKESH SAHNI</h3>
                                <p className="management-designation">Director Marketing &  Business Development</p>
                            </div>
                            <div className="management-description">
                                <p className="mamanemnet-description-content text-justify">A B.E. Electrical Engineer, Mr. Sahni has 15 years of experience in energy conservation and infrastructure projects. As Director of M/S Appu Ghar Entertainment Ltd., he leads business development in Asia and is currently engaged in cargo airline operations in West Africa.</p>
                            </div>
                        </div>
                    </div>


                    <div className="our-management-card-conatiner">
                        <div className="our-card-wrapper grid grid-cols-1 md:grid-cols-2 gap-3">
                            <div className="image-text-conatainer">
                                <img src={visahlL} alt="" srcset="" className='image-our-management' />
                                <h3 className="management-name">VISHAL LONKAR</h3>
                                <p className="management-designation">Director - Acquisition & Development</p>
                            </div>
                            <div className="management-description">
                                <p className="mamanemnet-description-content text-justify">Vishal Lonkar, with 19 years in consulting, retail, and hospitality, led brand expansion at Renest Hotels. At Futura Hotels, he heads Acquisition and Development, excelling in property management, hotel conversions, and investment raising. Known for innovation and strong financial acumen, he drives strategic growth</p>
                            </div>
                        </div>
                    </div>



                    <div className="our-management-card-conatiner">
                        <div className="our-card-wrapper grid grid-cols-1 md:grid-cols-2 gap-3">
                            <div className="image-text-conatainer">
                                <img src={Chirag} alt="" srcset="" className='image-our-management' />
                                <h3 className="management-name">CHIRAG MERCHANT</h3>
                                <p className="management-designation">Consultant </p>
                            </div>
                            <div className="management-description">
                                <p className="mamanemnet-description-content text-justify">Chirag Merchant, with 25+ years in Operations, Strategy, and Investments, was a Private Banker at JM Financial Services. He holds a Master’s from Jamnalal Bajaj Institute (2016) and enjoys photography, traveling, and tennis.</p>
                            </div>
                        </div>
                    </div>


                    <div className="our-management-card-conatiner-data">
                        <div className="image-card-container items-center grid grid-cols-1 md:grid-cols-2 gap-2">
                            <div className="img-1"><img src={sceniuz} alt="" srcset="" /></div>
                            <div className="img-1"><img src={Futura} alt="" srcset="" /></div>
                            <div className="img-1"><img src={P16} alt="" srcset="" /></div>
                            <div className="img-1"><img src={Happy} alt="" srcset="" /></div>
                        </div>
                    </div>

                </div>
            </div>

            <Footer/>
        </>
    )
}

export default Team
