import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../assets/Mahapatra_Logo.png";
import Nested from "./Nested.jsx";

const data = [
  {
    text: "About Us",
    path: "/about-us",
  },
  {
    text: 'Business',
    child: [
      {
        text: 'Engineering',
        path: 'https://eng.mahapatrauniversal.com/',
        target: "_blank",
      },
      {
        text: 'Information Technology',
        path: "https://sceniuz.com/",
      },
      {
        text: 'Manufacturing',
        child: [
          {
            text: 'Electrical & Mechanical Fitments',
            path: '/electrical_mechanical_fitments',
          },
          {
            text: 'Solar Power Products',
            path: '/solar-power-products',
          },
          {
            text: 'LED Lighting',
            path: '/led-lighting',
          },
          {
            text: 'Speciality Chemical',
            path: '/speciality-chemical',
          },
          {
            text: 'Consumer & Retail',
            path: '/consumer-retail',
          },
        ],
      },
      {
        text: 'Infrastructure',
        child: [
          {
            text: 'Road Infrastructure',
            path: '/road-infrastructure',
          },
          {
            text: 'Industrial Infrastructure',
            path: '/industrial-infrastructure',
          },
          {
            text: 'Urban Infrastructure',
            path: '/urban-infrastructure',
          },
          {
            text: 'Other Infrastructure',
            path: '/other-infrastructure',
          },
        ],
      },
    ],
  },
  {
    text: "Leadership",
    path: "/leadership",
  },
  {
    text: "Contact Us",
    path: "/contact-us",
    
  },
];

const Navbar = () => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const closeNavbar = () => {
    setShowDropDown(false);
    setIsOpen(false);
  };

  // console.log(showDropDown,isOpen);

  const toggleHamburger = () => {
    setIsOpen(!isOpen);
    setShowDropDown(!showDropDown);
  };

  console.log(showDropDown, isOpen,'<<<<<<<<<<<<<')

  return (
    <>
      <nav style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: '100px', padding: '20px', marginLeft: '30px', marginRight: '30px' }}>
        <div>
          <Link to="/"><img src={logo} alt="Logo" width="200px" /></Link>
        </div>
        <div className="desktop-links">
          <Nested data={data} closeNavbar={closeNavbar} />
        </div>
        <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleHamburger}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </nav>
      {showDropDown && (
        <div className="mobile-dropdown">
          <Nested data={data} closeNavbar={closeNavbar} />
        </div>
      )}
    </>
  );
};

export default Navbar;