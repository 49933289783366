import React from 'react';
import "./infra.css"
import road from "../../assets/road.png"
import Footer from '../../Leadership/Footer';

const Road = () => {
    return (
        <>
            <div className="road-infra-container-wrapper pt-5 pl-8 pr-8 pb-0">
                <h1 className="road-infra-header">ROAD INFRASTRUCTURE</h1>


                <div className="road-infra-container grid grid-cols-1 md:grid-cols-2 gap-5 mt-10">
                    <div className="road-infra-text-data-container">
                        <h1 className="road-infra-header-text">Highway Construction, Operation & Maintenance</h1>
                        <p className="road-infra-text-para text-justify">Road network plays a very vital role in overall development for any country. With one of the largest Road Network of 5.2 million kms, the Indian Roads carry 65% of freight traffic and 80% of passenger traffic. Of the total road traffic, 40% is serviced by National Highways with a total length of more than 100,000 kms thereby attracting huge investments. Huge opportunities are available to Company in its core competence area of Civil Engineering projects particularly in the roads and highways. With huge investment expected in the infrastructure sector, its revival is considered key growth of India. In the various sectors of infrastructure, roads play a vital role in the overall development of India. The outlook on investment in National Highways looks good as investment is expected to increase at a CAGR of 30% over the next 5 years. Apart from constructing new roads the Government is also focusing on the Operations & Maintenance (O&M) of the existing roads. Although there is requirement of more concentrated efforts on the O&M side of the Highways by way of higher allocation of funds for the same. In financial year 2015-16, NHAI has made budgetary provisions of Rs. 1300 crores of Grant towards maintenance and repair of National Highways. The allocation of Rs. 2200 crores has been made as on January 2016 for maintenance of National Highways during 2015-16 for States / Union Territories. The Company has an early mover advantage into this segment.</p>
                    </div>
                    <div className="image-road-container">
                        <img src={road} alt="" srcset="" />
                    </div>
                </div>
            </div>

            {/*  */}
            <Footer/>
        </>
    )
}

export default Road
