import React from 'react';
import "./manufacture.css"
import suspension1 from "../../assets/suspension1.png";
import suspension2 from "../../assets/suspension2.png";
import wire from "../../assets/wire.png";
import Hardware from '../../assets/Hardware.png'
import Footer from "../../Leadership/Footer"

const Electrical_Mechanical = () => {
  return (
    <>
      <div className="electrical-fitments-container-wrapper pl-8 pr-8 pb-0">
        <div className="electrical-container-header">
          <h1 className='electrical-header-text'>ELECTRICAL & MECHANICAL FITMENTS</h1>
        </div>

        <div className="insulator-hardware-conatiner">
          <h2 className="header-text">INSULATOR HARDWARE</h2>
          <p className="para-electrical-header">The entire range of insulator hardwares for Suspension & Tension strings upto six bundle conductor for application on transmission line of various ratings upto 800 KV.r</p>

          <div className="image-conatiner-column-data grid grid-cols-1 md:grid-cols-2 gap-20">
            <div className="image-container-wrapper-data">
              <img src={suspension1} alt="" />
              <p className="image-text-data">Suspension String</p>
            </div>
            <div className="image-container-wrapper-data">
              <img src={suspension2} alt="" />
              <p className="image-text-data">Tension String</p>
            </div>
          </div>
        </div>



        {/* Conductors and ground wire */}
        <div className="wire-accssories-container">
          <h2 className="header-text">CONDUCTOR & GROUND WIRE ACCESSORIES</h2>
          <p className="para-electrical-header">We offer the entire range of Conductor Accessories for single or bundle Conductors. These include Midspan Compression Joints, Repair Sleeves, parallel Groove Clamps, Vibration Dampers, Spacer Dampers for Twin, Triple, Quad & Hexa Bundle Conductors, all types of Spacers and Armour Rods. Accessories for various sizes of Ground Wire are also manufactured which include Suspension Assemblies, Tension Assemblies, Midspan Joints, Repair Sleeves and Earthing Rods.</p>


          <div className="image-wire-accessories-conatiner">
            <img src={wire} alt="" srcset="" className='image-wire'/>
          </div>
        </div>


        {/* SUB-STATION HARDWARE, CLAMPS & CONNECTORS */}
        <div className="wire-accssories-container">
          <h2 className="header-text">SUB-STATION HARDWARE, CLAMPS & CONNECTORS</h2>
          <p className="para-electrical-header">We have been manufacturing a whole range of Hardware and Accessories for application in Various Substations of different ratings. Those include Tension and Suspension string with and without Turn Buckle, IPS Tubes, clamps and connectors of different type like Parallel groove Clamps & T-Connectors.</p>


          <div className="image-wire-accessories-conatiner">
            <img src={Hardware} alt="" srcset="" className='image-wire'/>
          </div>
        </div>
      </div>

     <Footer/>
    </>
  )
}

export default Electrical_Mechanical
