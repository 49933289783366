import React from 'react'
import "./manufacture.css"
import silicate from "../../assets/selicate.png";
import sodium_meta from "../../assets/sodium-meta.png";
import gel from "../../assets/gel.png";
import sand from "../../assets/sand.png";
import ash from "../../assets/ash.png";
import sodium_silicate from "../../assets/sodium-silicate.png";
import Footer from "../../Leadership/Footer"

const Chemical = () => {
  return (
    <>
    <div className="chemical-container-data pl-8 pr-8 pb-0">
        <div className="chemaical-text-wrapper-container">
            <h1 className="chemical-header">SPECIALITY CHEMICALS</h1>
            <p className="chemical-header-para">We manufacture sodium silicate in different forms as per the customers requirement. We are pioneer in manufacturing sodium silicate for more than 45 years. The application of sodium silicate is very wide. Sodium Silicate is used widely due to its different molecular ratio and specific weight, the liquid Sodium Silicate is used to manufacture laundry soap, detergent cake, sodium met silicate, fire resisting & acid proof cement, steel & alloy castings, electrodes, dyeing, printing, textile auxiliaries & ceramics. Because of its unique properties, sodium silicate is used in various applications to manufacture Silica Gel tooth paste precept silica, zeolite, construction chemicals, oil field chemicals, paints, pigments, paper making, paper cones, tubes, foundry, fluxes and fiber drum. Because of its binding properties Sodium Silicate is the best water proofing agent and it is also used in acid & alkali proof linings. The Products are: Sodium Silicate, Alkaline Grade Silicate, Solid Glass Silicate, Liquid Silicate, Neutral Grade Silicate.</p>
        </div>


        <div className="chemical-data-image-container grid grid-cols-1 md:grid-cols-3 gap-5 mt-10">
            <div className="chemical-container-info">
                <img src={silicate} alt="" srcset="" className='chemical-image' />
                <p className="text-image-chemical">Silicate</p>
            </div>

            <div className="chemical-container-info">
                <img src={sodium_meta} alt="" srcset="" className='chemical-image' />
                <p className="text-image-chemical">Sodium meta silicate</p>
            </div>

            <div className="chemical-container-info">
                <img src={gel} alt="" srcset="" className='chemical-image' />
                <p className="text-image-chemical">Silica Gel</p>
            </div>

            <div className="chemical-container-info">
                <img src={sand} alt="" srcset="" className='chemical-image' />
                <p className="text-image-chemical">Silica Sand</p>
            </div>

            <div className="chemical-container-info">
                <img src={ash} alt="" srcset="" className='chemical-image' />
                <p className="text-image-chemical">Soda ash</p>
            </div>

            <div className="chemical-container-info">
                <img src={sodium_silicate} alt="" srcset="" className='chemical-image' />
                <p className="text-image-chemical">Sodium Silicate</p>
            </div>
        </div>
    </div>

    <Footer/>
    </>
  )
}

export default Chemical
