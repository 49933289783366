import React from 'react'
import metro from "../../assets/metro.png"
import "./infra.css"
import Port1 from "../../assets/Port1.png";
import Port2 from "../../assets/Port2.png"
import housing from "../../assets/housing.png"
import Footer from "../../Leadership/Footer"

const OtherInfra = () => {
    return (
        <>
            <div className="other-infra-container-wrapper  pt-5 pl-8 pr-8 pb-0">
                <div className="other-infra-header-container">
                    <h1 className="other-infra-header">OTHER INFRASTRUCTURE</h1>
                </div>

                <div className="">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                        <div className="flex flex-col w-[38%] max-md:ml-0 max-md:w-full">
                            <div className="flex flex-col grow justify-center rounded-xl max-md:mt-6">
                                <div className="flex flex-col justify-center rounded-xl">
                                    <img
                                        loading="lazy"
                                        src={metro}
                                        className="w-full aspect-[1.08]"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col ml-5 w-[62%] max-md:ml-0 max-md:w-full">
                            <div className="flex flex-col self-stretch px-5 my-auto max-md:mt-10 max-md:max-w-full">
                                <div className="justify-center text-2xl font-medium tracking-tight text-red-900 whitespace-nowrap max-md:max-w-full">
                                    Railways/Metro
                                </div>
                                <div className="other-infira-para">
                                    Indian Railways have embarked upon expansion program as per Vision
                                    Document 2020. Metro Railways are being constructed in major
                                    cities all over country. Over the next 10-12 years, the Railways
                                    are looking at investing US$500 billion to increase capacity and
                                    upgrade existing infrastructure.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="self-stretch mt-5">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                        <div className="flex flex-col w-[59%] max-md:ml-0 max-md:w-full">
                            <div className="flex flex-col grow px-5 max-md:mt-6 max-md:max-w-full gap-2">
                                <div className="justify-center self-start text-2xl font-medium tracking-tight text-red-900">
                                    Inland Waterways/Ports
                                </div>
                                <div className="port-text-data">
                                    Waterways freight transportation is under-utilized in India
                                    compared to the US, China and the European Union. India has
                                    recognized 106 waterways of which six are declared as national
                                    waterways. Economic viability of a waterway to carry traffic as an
                                    alternative to rail and road depends on its length - a minimum 500
                                    km and 250 km for both cases respectively – as well as large
                                    hinterland coverage area and the potential to generate adequate
                                    traffic. Globally, inland waterways have proved to be
                                    cost-effective and environment friendly. This is also true in
                                    India – for instance, the cost of moving coal via coastal shipping
                                    is one-sixth of the cost of moving it by railways. The development
                                    of inland waterways can transform the inland transportation of
                                    goods. India possesses an extensive network of inland waterways in
                                    the form of rivers, canals, backwaters and creeks. Of the total
                                    navigable length of 14500 km, 5200 km of river and 4000 km of
                                    canals can be used by mechanized vessels. India’s inland waterways
                                    potential has been estimated at around 30 MMT of cargo.
                                    <br />
                                    Jal Marg Vikas (National Waterway-I) is a 1620 km project on River
                                    Ganges between Allahabad and Haldia, which will enable the
                                    commercial navigation of 2000-tonne vessels. The project will be
                                    completed over six years following an investment of Rs. 4200
                                    crore. India has 12 major ports and 187 non-major ports. These 12
                                    ports handle approximately 95% of the trading volume and 70% of
                                    the trade by value in India. The Government announced major
                                    initiatives in the 2016-17 Budget. The Government also plans to
                                    develop Greenfield ports on the Eastern and Western coasts under
                                    the flagship Sagarmala project.
                                    <br />
                                    Mahapatra Universal Limited intends to engage in civil engineering
                                    projects related to port construction, harbor/marine structures,
                                    water supply/sanitation, bridges, viaducts and elevated
                                    structures. The Company has tied up with company engaged in the
                                    construction and civil engineering business with proven expertise
                                    in undertaking major and complex maritime works.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col ml-5 w-[41%] max-md:ml-0 max-md:w-full">
                            <div className="flex flex-col grow self-stretch max-md:mt-6 max-md:max-w-full">
                                <div className="flex flex-col justify-center rounded-xl max-md:max-w-full">
                                    <div className="flex flex-col justify-center max-md:max-w-full">
                                        <img
                                            loading="lazy"
                                            src={Port1}
                                            className="w-full aspect-[1.35] max-md:max-w-full"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col justify-center mt-6 max-md:max-w-full">
                                    <div className="flex flex-col justify-center max-md:max-w-full">
                                        <img
                                            loading="lazy"
                                            src={Port2}
                                            className="w-full aspect-[1.35] max-md:max-w-full"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className=" mt-6">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                        <div className="flex flex-col w-[38%] max-md:ml-0 max-md:w-full">
                            <div className="flex flex-col grow justify-center rounded-xl max-md:mt-6">
                                <div className="flex flex-col justify-center rounded-xl">
                                    <img
                                        loading="lazy"
                                        src={housing}
                                        className="w-full aspect-[1.08] image-data"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col ml-5 w-[62%] max-md:ml-0 max-md:w-full">
                            <div className="flex flex-col self-stretch px-5 my-auto max-md:mt-10 max-md:max-w-full">
                                <div className="justify-center text-2xl font-medium tracking-tight text-red-900 whitespace-nowrap max-md:max-w-full">
                                    Housing Infrastructure
                                </div>
                                <div className="other-infira-para">
                                    The Indian housing sector is one of the fastest growing across the world. Growing population, rising income levels and rapid urbanization have catalyzed sustained growth. This sector contributes 5-6% of India’s GDP. The total housing shortage in 2015 was estimated to be 33.58 mn units, with urban housing shortage at 18.78 mn units and rural housing shortage at 14.8 mn units. Mahapatra Universal Limited is in the process of building low cost houses for the needy and deserve Indian who dream of a home of their own. We plan to build 1crore homes in the next 10 years across India.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </>
    )
}

export default OtherInfra
