import React, { useRef } from 'react';
import mail from "../assets/mail.png";
import phone from "../assets/phone.png"
import { Link } from 'react-router-dom';
import "./contact.css"
import contact from "../assets/contact.png"
import emailjs from "@emailjs/browser";


const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_2j2zwj2",
                "template_ztr5bmk",
                form.current,
                "3EfGNTMLPrr9Rn3dH"
            )
            .then(
                (result) => {
                    console.log(result.text);
                    alert("Email Send Successfully");
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };

    return (
        <>
            <div className="contact-us-container-wrapper mt-5">
                <div className="contact-us-container">
                    <h1 className="contact-us-header">Contact Us</h1>

                    <div className="contact-us-detail-wrapper">
                        <div className="contact-us-wrapper p-2">
                            <img src={mail} alt="" srcset="" className='icon-data' />
                            <Link to="" className="pemail-text">contactus@mahapatrauniversal.com</Link>
                        </div>
                        <div className="contact-us-wrapper p-2">
                            <img src={phone} alt="" srcset="" className='icon-data' />
                            <Link to="" className="pemail-text">+91 22 6264 2345</Link>
                        </div>
                    </div>

                    <div className="contact-us-form-conatiner grid grid-cols-1 md:grid-cols-2 mt-5 gap-4">
                        <div className="conatct-us-form-conatiner">
                            <form action="" method="post" className='form-data-field' ref={form}
                                onSubmit={sendEmail}>
                                <input type="text" name="user_name" id="inputID" placeholder='Name' className='input-name' />
                                <input type="number" name="phone_number" id="inputID" placeholder='Phone Number' className='input-name' />
                                <input type="email" name="user_email" id="inputID" placeholder='Email Id' className='input-name' />
                                <textarea className='input-name' name="message" id="inputID" placeholder='Message'></textarea>

                                <button className='btn-container' type="submit" value="Send">Submit</button>
                            </form>
                        </div>
                        <div className="contact-image-container-data p-2">
                            <img src={contact} alt="" srcset="" />
                        </div>
                    </div>
                </div>
            </div>
            

        </>
    )
}

export default Contact
