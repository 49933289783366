import React from 'react';
import "./manufacture.css"
import led1 from "../../assets/led1.png";
import led2 from "../../assets/led2.png";
import led3 from "../../assets/led3.png";
import led4 from "../../assets/led4.png";
import led5 from "../../assets/led5.png";
import led6 from "../../assets/led6.png";
import led7 from "../../assets/led7.png";
import Footer from "../../Leadership/Footer"

const Led = () => {
    return (
        <>
            <div className="led-lightining-data-container pl-8 pr-8 pb-0">
                <div className="header-name-conatiner">
                    <h1 className="led-header">LED LIGHTING</h1>
                </div>

                <div className="led-data-card-fields grid grid-cols-1 md:grid-cols-2 gap-5">
                    <div className="led-card-container">
                        <img src={led1} alt="" srcset="" className='led-image'/>
                        <h5 className="led-card-text">Batten LED Tube Light For Home Lighting Systems</h5>
                        <p className="led-para-text-data">Elegant design with decorative polycarbonate end caps, Uniform glare free light with SMD LED technology. Aluminum extruded heat sink for effective heat management. Wall mounted, protected from dust and insects.</p>
                    </div>

                    <div className="led-card-container">
                        <img src={led2} alt="" srcset="" className='led-image'/>
                        <h5 className="led-card-text">LED Streetlights</h5>
                        <p className="led-para-text-data">We have designed and developed latest state of art LED Street lighting Solutions. By replacing conventional lighting and using LED Street lighting energy can be saved up to 40 %.An LED street light is an integrated light that uses light emitting diodes (LED) as its light source.</p>
                    </div>


                    <div className="led-card-container">
                        <img src={led3} alt="" srcset="" className='led-image'/>
                        <h5 className="led-card-text">LED Moving Display Board</h5>
                        <p className="led-para-text-data">IP based LED Message Scrolling Display Board as per your requirement. Bright LED display boards are visible from long distance say up to 100m or even more. Our unique customization and integration services reduces delay to almost NIL or negligible.</p>
                    </div>


                    <div className="led-card-container">
                        <img src={led4} alt="" srcset="" className='led-image'/>
                        <h5 className="led-card-text">LED Down Lights</h5>
                        <p className="led-para-text-data">An LED-based system such as a down light needs more than just efficient, high quality LEDs. Optical, thermal, and power solutions that are geared towards optimal performance and longevity of the system are key elements to the success of the application.</p>
                    </div>


                    <div className="led-card-container">
                        <img src={led5} alt="" srcset="" className='led-image'/>
                        <h5 className="led-card-text">LED Panel Lights</h5>
                        <p className="led-para-text-data">Application Areas Widely used for various commercial lighting and residential lighting applications, such as offices, shopping mall, hotel, restaurant, hospital, home lighting etc.</p>
                    </div>


                    <div className="led-card-container">
                        <img src={led6} alt="" srcset="" className='led-image'/>
                        <h5 className="led-card-text">Astronomical Timer Switch</h5>
                        <p className="led-para-text-data">Time Switches are used to control events with respect to real time clock (RTC) whereas timers are used to control processing times. Therefore RTC forms the basic difference between timer and time switch functionality.</p>
                    </div>


                    <div className="led-card-container">
                        <img src={led7} alt="" srcset="" className='led-image'/>
                        <h5 className="led-card-text">Our Other Products</h5>
                        <p className="led-para-text-data">GPS clocks for accurate time, LED characters</p>
                    </div>
                </div>
            </div>


            <Footer/>
        </>
    )
}

export default Led
