import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import Team from './Components/Leadership/Team';
import Electrical_Mechanical from './Components/Business/Manufacturing/Electrical_Mechanical';
import Led from './Components/Business/Manufacturing/Led';
import Chemical from './Components/Business/Manufacturing/Chemical';
import Solar from './Components/Business/Manufacturing/Solar';
import Road from './Components/Business/Infrastructure/Road';
import Industry from './Components/Business/Infrastructure/Industry';
import Urban from './Components/Business/Infrastructure/Urban';
import OtherInfra from './Components/Business/Infrastructure/OtherInfra';
import Consumer from './Components/Business/Manufacturing/Consumer';
import Abt from './Components/Home/Abt';
import Contact from './Components/Contact/Contact';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<Abt/>} />
        <Route path="/leadership" element={<Team />} />
        <Route path="/electrical_mechanical_fitments" element={<Electrical_Mechanical />} />
        <Route path="/led-lighting" element={<Led />} />
        <Route path="/speciality-chemical" element={<Chemical />} />
        <Route path="/solar-power-products" element={<Solar />} />
        <Route path="/consumer-retail" element={<Consumer />} />
        <Route path="/road-infrastructure" element={<Road />} />
        <Route path="/industrial-infrastructure" element={<Industry />} />
        <Route path="/urban-infrastructure" element={<Urban />} />
        <Route path="/other-infrastructure" element={<OtherInfra />} />



        <Route path="/contact-us" element={<Contact/>} />
      </Routes>
    </Router>
  );
}

export default App;
