import React from 'react'
import "./infra.css"
import industrial from "../../assets/industry.png"
import Footer from '../../Leadership/Footer'

const Industry = () => {
    return (
        <>
            <div className="road-infra-container-wrapper pt-5 pl-8 pr-8 pb-0">
                <h1 className="road-infra-header">INDUSTRIAL INFRASTRUCTURE</h1>


                <div className="road-infra-container grid grid-cols-1 md:grid-cols-2 gap-5 mt-10">
                    <div className="road-infra-text-data-container">
                        <h1 className="road-infra-header-text">Mahapatra Universal Limited is engaged in Industrial Infrastructure Development projects across the country.</h1>
                        <p className="road-infra-text-para text-justify">The main Objects of the Industrial Infrastructure division of Mahapatra Universal Limited are as under:</p>
                        <ul className='list-disc ml-8'>
                            <li>To develop or to assist in the development of Industrial Growth Centers/Areas in India in general and in the areas of its jurisdiction in particular as per the requirement of different State Government and also the Central Government policies. For this purpose to take suitable steps to obtain/acquire land from Government or private sources and/or to arrange, coordinate the availability of all essential infrastructural inputs -basic facilities such as Roads, Water, Power and Plantation etc.</li>
                            <li>To promote, encourage and assist the establishment, growth and development of industries and industrialization in India such as Textile Parks, SEZ and other industrial park.</li>
                            <li>To prepare, undertake and promote industrial Schemes either solely or jointly with the Government or any Corporation, Company, Association, Institution or individuals.</li>
                            <li>To encourage and take steps for procurement and establishment of public sector, industrial projects</li>
                            <li>To encourage and take all steps to attract and invite entrepreneurs, industrialists, businessmen and promoters to promote, establish industrial projects and enterprises in the are Mahapatra Universal Limited is involved.</li>
                        </ul>
                    </div>
                    <div className="image-road-container">
                        <img src={industrial} alt="" srcset="" />
                    </div>
                </div>
            </div>

            {/*  */}
            <Footer />
        </>
    )
}

export default Industry
