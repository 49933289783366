import React from 'react';
import "./manufacture.css"
import solar1 from "../../assets/solar1.png";
import solar2 from "../../assets/solar2.png";
import solar3 from "../../assets/solar3.png";
import solar4 from "../../assets/solar4.png";
import solar5 from "../../assets/solar5.png";
import solar6 from "../../assets/solar6.png";
import solar7 from "../../assets/solar7.png";
import solar8 from "../../assets/solar8.png";
import solar9 from "../../assets/solar9.png";
import solar10 from "../../assets/solar10.png";
import solar11 from "../../assets/solar11.png";
import solar12 from "../../assets/solar12.png";
import solar13 from "../../assets/solar13.png";
import Footer from "../../Leadership/Footer";


const Solar = () => {
    return (
        <>
            <div className="chemical-container-data pl-8 pr-8 pb-0">
                <div className="chemaical-text-wrapper-container">
                    <h1 className="chemical-header">SOLAR POWER PRODUCTS</h1>
                </div>


                <div className="chemical-data-image-container grid grid-cols-1 md:grid-cols-2 gap-5 mt-10">
                    <div className="solar-container-info">
                        <img src={solar1} alt="" srcset="" className='solar-image' />
                        <h5 className="solar-header-text">Solar Modules</h5>
                        <p className="para-image-solar">We have been established to generate Non-Conventional Power in India. The USP of the company is to ensure Energy efficient solutions and is inclined towards developing solar energy projects, to provide economical and efficient green energy.</p>
                    </div>

                    {/* solar 2 */}
                    <div className="solar-container-info">
                        <img src={solar2} alt="" srcset="" className='solar-image' />
                        <h5 className="solar-header-text">Solar On Grid & Off Grid Projects</h5>
                        <p className="para-image-solar">We have designed and developed off grid power system packages at all wattage & price levels. The systems are all "stand alone" systems, capable of producing electricity from solar energy and storing it for use when the sun is not shining.</p>
                    </div>


                    {/* solar 3 */}
                    <div className="solar-container-info">
                        <img src={solar3} alt="" srcset="" className='solar-image' />
                        <h5 className="solar-header-text">Solar Pump Sets</h5>
                        <p className="para-image-solar">With its rich international technological experience has developed unique and cost effective solar solution for agricultural pumps. This is the best solution during day time to get free water dwelling / pumping systems.</p>
                    </div>

                    {/* solar 4 */}
                    <div className="solar-container-info">
                        <img src={solar4} alt="" srcset="" className='solar-image' />
                        <h5 className="solar-header-text">Solar Home Lighting Systems</h5>
                        <p className="para-image-solar">We have designed and developed Home Lighting Systems which are used when there is no availability of power. This solution is more useful for low cost homes, shops to provide 24 hour power with LED Bulbs, DC Fans, Mobile Charging etc.</p>
                    </div>


                    {/* solar 5 */}
                    <div className="solar-container-info">
                        <img src={solar5} alt="" srcset="" className='solar-image' />
                        <h5 className="solar-header-text">Solar Lanterns</h5>
                        <p className="para-image-solar">A Solar lamp also known as solar light or solar lantern is a lighting system composed of an LED lamp, solar panels, battery, charge controller. The lamp operates on electricity from batteries, charged through the use of solar photovoltaic panel.</p>
                    </div>

                    {/* solar 6 */}
                    <div className="solar-container-info">
                        <img src={solar6} alt="" srcset="" className='solar-image' />
                        <h5 className="solar-header-text">Solar Powered LED Streetlights</h5>
                        <p className="para-image-solar">We have designed and developed latest state of art LED Street lighting Solutions. By replacing conventional lighting and using LED Street lighting energy can be saved up to 40%. An LED street light is an integrated light.</p>
                    </div>

                    {/* solar 7 */}
                    <div className="solar-container-info">
                        <img src={solar7} alt="" srcset="" className='solar-image' />
                        <h5 className="solar-header-text">Solar Powered Rural Water Drinking Systems</h5>
                        <p className="para-image-solar">With its rich international technological experience has developed unique and cost effective solar solution for rural drinking water supply schemes for Public health engineering departments.</p>
                    </div>

                    {/* solar 8 */}
                    <div className="solar-container-info">
                        <img src={solar8} alt="" srcset="" className='solar-image' />
                        <h5 className="solar-header-text">Solar Water Heater</h5>
                        <p className="para-image-solar">Solar Water Heating System can heat the water up to 80˚C.Solar water heaters (SWHS) of 100-500 liters capacity are suited for domestic application. Larger systems (above 500 LPD) can be used in restaurants etc.</p>
                    </div>

                    {/* solar 9 */}
                    <div className="solar-container-info">
                        <img src={solar9} alt="" srcset="" className='solar-image' />
                        <h5 className="solar-header-text">All In One Solar LED Streetlights</h5>
                        <p className="para-image-solar">Very high efficiency solar energy component new type of Lithium battery, intelligent solar controller and Panasonic infrared induction sensor, and the compact solar lights is easy to adapt to a range of application.</p>
                    </div>

                    {/* solar 10 */}
                    <div className="solar-container-info">
                        <img src={solar10} alt="" srcset="" className='solar-image' />
                        <h5 className="solar-header-text">Mobile Solar Powered Water ATM</h5>
                        <p className="para-image-solar">With its rich international technological experience has developed unique and cost effective solar solution for mobile solar power water ATM. Users are issued a card they can use to claim a daily share of water.</p>
                    </div>

                    {/* solar 11 */}
                    <div className="solar-container-info">
                        <img src={solar11} alt="" srcset="" className='solar-image' />
                        <h5 className="solar-header-text">Our Other Products</h5>
                        <p className="para-image-solar1">
                            <ul className='list-disc'>
                                <li>Solar high mast light systems</li>
                                <li>Solar structures</li>
                                <li>Solar panel cleaning machines</li>
                                <li>Solar cold storage systems/Solar refrigerators</li>
                                <li>Solar Rural Electric Power Distribution Systems</li>
                            </ul>
                        </p>
                    </div>

                    {/* solar 12 */}
                    <div className="solar-container-info">
                        <img src={solar12} alt="" srcset="" className='solar-image' />
                        <h5 className="solar-header-text">DC Fans – Table & Ceiling fans</h5>
                        <p className="para-image-solar">A solar fan with externally mounted solar panels A solar fan is a mechanical fan powered by solar panels. The solar panels are either mounted on the device or are installed independently.</p>
                    </div>

                    {/* solar 13 */}
                    <div className="solar-container-info">
                        <img src={solar13} alt="" srcset="" className='solar-image' />
                        <h5 className="solar-header-text">Solar Rooftops For Petrol Bunks</h5>
                        <p className="para-image-solar">A Min of 7 hrs Power cut, a monthly bill of Rs 25000/- for diesel and electricity bill of Rs 6000/- was brought down to a total of Rs 2000/- only. Huge reduction in hefty electricity bills. Quick and higher ROI</p>
                    </div>

                </div>
            </div>


            <Footer/>
        </>
    )
}

export default Solar
