import React from 'react'
import "../Infrastructure/infra.css"
import consumer_img from "../../assets/consumer.png"
import Footer from '../../Leadership/Footer'

const Consumer = () => {
    return (
        <>
            <div className="road-infra-container-wrapper pl-8 pr-8 pb-0">
                <div className="road-infra-container grid grid-cols-1 md:grid-cols-2 gap-5 mt-10">
                    <div className="road-infra-text-data-container">
                        <h1 className="consumer-header-text">CONSUMER & RETAIL</h1>
                        <p className="consumer-text-para text-justify">INDIANNESS by believing in oneself and doing things the Indian way- this simple idea has been the core of our business since inception of our consumer and retails division. Today a wide portfolio of brands in food, FMCG and Fashion complement the country’s pioneering modern retail networks. We the vision that our customers and stakeholders are best served by creating and executing future scenarios in the consumption space leading to economic development. We will be the trendsetters in evolving consumer brands and delivery formats and by making consumption affordable for all customer segments. We shall infuse Indian brands with confidence and renewed ambition. We shall be efficient, cost- conscious and committed to quality in whatever we do. We shall ensure that our positive attitude, sincerity, humility and united determination shall be the driving force to make us successful. Our flagship retail brand Preeti’s is ranked among the most valuable Indian retail startup brands and the among the most trusted brand in mini modern retail stores. With a growing chain of small neighborhood stores, we are in the path of opening 1 store every week. We have a target to open 10000 neighborhood stores by the year 2022.</p>
                    </div>
                    <div className="image-road-container">
                        <img src={consumer_img} alt="" srcset="" />
                    </div>
                </div>
            </div>

            <Footer />

        </>
    )
}

export default Consumer
