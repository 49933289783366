import React from 'react'
import "./home.css"
import about_img from "../assets/About_img.png";
import image_about from "../assets/about-img-2.png";
import Footer from '../Leadership/Footer';

const Abt = () => {
  return (
   <>
       <div className="about-home-page-container mt-5 grid grid-cols-1 md:grid-cols-2 gap-3 p-5">
        <div className="about-page-container-image flex">
            <img src={about_img} alt="" srcset="" />
        </div>
        <div className="about-page-data-container">
            <h1 className="about-us-header">ABOUT US</h1>
            <p className="about-us-para">
            Mahapatra Universal Limited, headquartered in Mumbai, is a global enterprise operating in over seven countries across three continents. With diversified interests in Manufacturing, Engineering, Infrastructure, IT, Consumer and Retail, Financial Services, Healthcare, Sports, Media, and Entertainment, its mission is "To improve the quality of life of the communities we serve globally, through long-term stakeholder value creation based on Leadership with Trust." The company has taken pioneering initiatives across its sectors, contributing significantly to the country's growth and the communities it serves.
            </p>
            <img src={image_about} alt="" srcset="" />
        </div>
    </div>

    <Footer/>
   </>
  )
}

export default Abt
