import React from 'react'
import about_img from "../assets/About_img.png";
import image_about from "../assets/about-img-2.png";
import "./home.css"
import Integrity from "../assets/Integrity.png";
import Excellence from "../assets/Excellence.png";
import Unity from '../assets/Unity.png'
import team1 from "../assets/team1.png";
import team2 from "../assets/team2.png";
import team3 from "../assets/team3.png";
import team4 from "../assets/team4.png";
import team5 from "../assets/team5.png";
import team6 from "../assets/team6.png";

const About = () => {
  return (
    <>
    <div className="about-home-page-container mt-5 grid grid-cols-1 md:grid-cols-2 gap-3">
        <div className="about-page-container-image flex">
            <img src={about_img} alt="" srcset="" />
        </div>
        <div className="about-page-data-container">
            <h1 className="about-us-header">ABOUT US</h1>
            <p className="about-us-para">
            Mahapatra Universal Limited, headquartered in Mumbai, is a global enterprise operating in over seven countries across three continents. With diversified interests in Manufacturing, Engineering, Infrastructure, IT, Consumer and Retail, Financial Services, Healthcare, Sports, Media, and Entertainment, its mission is "To improve the quality of life of the communities we serve globally, through long-term stakeholder value creation based on Leadership with Trust." The company has taken pioneering initiatives across its sectors, contributing significantly to the country's growth and the communities it serves.
            </p>
            <img src={image_about} alt="" srcset="" />
        </div>
    </div>



    {/* Our Values */}
    <div className="our-value-container-wrapper">
        <div className="our-value-wrapper">
            <h1 className="our-value-header">OUR VALUES</h1>
            <p className="our-value-para-header">The following  Values are the driving force for all Our businesses as they work towards delivering sustainable profitable growth in their respective sectors:</p>
        </div>

        <div className="our-value-card-container-wrapper mt-5 grid grid-cols-1 md:grid-cols-3 gap-3">
            <div className="our-value-card-data">
                <img src={Integrity} alt="" className="our-value-image h-56 w-screen" />
                <h3 className="our-value-card-text-conatainer">INTEGRITY</h3>
                <p className="our-value-card-para-container">Our fundamental virtue is characterized by adhering to ethical and moral principles consistently.</p>
            </div>

            <div className="our-value-card-data">
                <img src={Excellence} alt="" className="our-value-image h-56 w-screen rounded-xl" />
                <h3 className="our-value-card-text-conatainer">EXCELLENCE</h3>
                <p className="our-value-card-para-container">For us excellence is not just an outcome but a journey marked by passion effort and resilience.</p>
            </div>


            <div className="our-value-card-data">
                <img src={Unity} alt="" className="our-value-image h-56 w-screen" />
                <h3 className="our-value-card-text-conatainer">UNITY</h3>
                <p className="our-value-card-para-container">Building strength through togetherness.</p>
            </div>
        </div>




        {/* Our Culture */}

        <div className="our-value-wrapper">
            <h1 className="our-value-header">OUR CULTURE</h1>
            <p className="our-value-para-header">We Stand by  for all our ethics for all our customers, stakeholders, including our employees, and society at large.</p>
            <p className="our-value-para-header">We are guided by the principle of "Achieving right results by right means".  Transcending legal guidelines to engage deeply with all stakeholders. Emphasizing a journey rather than a destination, the company continually enhances its practices across continents, balancing stakeholder interests. Core values like teamwork, transparency, accountability, continuous learning, social responsibility, respect for individuals, and humility drive the organization, fostering an entrepreneurial culture and enhancing shareholder value.</p>
        </div>

        <div className="our-team-home-page-photomt-5 mt-4 grid grid-cols-1 md:grid-cols-3 gap-3">
            <div className="our-team-home-container">
                <img src={team1} alt="" srcset="" className='our-team'/>
            </div>
            <div className="our-team-home-container">
                <img src={team2} alt="" srcset="" className='our-team'/>
            </div>
            <div className="our-team-home-container">
                <img src={team3} alt="" srcset="" className='our-team'/>
            </div>
        </div>

        <div className="our-team-home-page-photomt-5 mt-4 grid grid-cols-1 md:grid-cols-3 gap-3">
            <div className="our-team-home-container">
                <img src={team4} alt="" srcset="" className='our-team' />
            </div>
            <div className="our-team-home-container">
                <img src={team5} alt="" srcset="" className='our-team' />
            </div>
            <div className="our-team-home-container">
                <img src={team6} alt="" srcset="" className='our-team' />
            </div>
        </div>


        <div className="our-value-wrapper">
            <h1 className="our-value-header">SOCIAL RESPONSIBILITY</h1>
            <p className="our-value-para-header">As a responsible corporate the company, besides being actively engaged in various sector is fully committed to the larger goal of enhancing the standard of living, particulllarly of the  marginalized . The company believes that only through this will the larger objective of fully developing and tapping the nation's true potential be achieved. Accordingly, the corporate policies of the company are driven by a set of seven carefully drafted core values. 'Respect For Individual' is one such core value of the company. In line with this, the company as a whole ensures that it adheres to all statutory regulations pertaining to Occupational Health, Safety and Environment along with gender and age specific issues of employment. Specifically in the context of medical, hygiene, rural development, education, food distribution and other social activities.</p>
        </div>

    </div>
    </>
  )
}

export default About
