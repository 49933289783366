import React from 'react'
import urban from "../../assets/urban.png";
import "./infra.css"
import Footer from '../../Leadership/Footer';

const Urban = () => {
  return (
    <>
    <div className="road-infra-container-wrapper data-urban pt-5 pl-8 pr-8 pb-0 items-center">
                <h1 className="road-infra-header">URBAN INFRASTRUCTURE</h1>


                <div className="road-infra-container items-center grid grid-cols-1 md:grid-cols-2 gap-5 mt-10">
                    <div className="road-infra-text-data-container">
                        <p className="road-infra-text-para text-justify">Around 10 million Indians migrate to cities each year. As per the World Economic Forum, India's urban population is expected to almost double to 814 million between 2014 and 2050. In FY15, the estimated housing shortage in urban areas was 18.78 mn houses. As per a World Economic Forum report, there is an estimated "US $110 billion funding gap that could hinder India's ability to provide basic urban infrastructure and services to its rapidly growing urban population." The present urban infrastructure is inadequate to address urban population demands. This provides the company with a huge opportunity into the sector.</p>
                    </div>
                    <div className="image-road-container">
                        <img src={urban} alt="" srcset="" />
                    </div>
                </div>
            </div>

            <Footer/>
    </>
  )
}

export default Urban
