import React from 'react';
import "./home.css"
import preeti from "../assets/Preeti_Mam.png";
import sceniuz from "../assets/Sceniuz_Logo.png";
import Futura from "../assets/Futura_Hotels.png"
import Preetis from "../assets/Preeti's.png";
import HappyHrs from "../assets/Happy_Hours.png" 
import About from './About';
import Contact from '../Contact/Contact';
import Team from '../Leadership/Team';
import Footer from '../Leadership/Footer';


const Home = () => {
    return (
        <>
            <section className="h-screen font-[SF Pro Display]">
                <div className="home-page-container-data pt-0 pl-8 pr-8 pb-0">
                    <figure className="hopme-page-data-wrapper md:flex bg-slate-100 rounded-xl p-18 md:p-0 dark:bg-slate-100 p-2 p-3">
                        <img className="image-data-home w-2/5 h-full md:w-58 md:h-auto md:rounded-none rounded-full mx-auto p-1 m-10" src={preeti} alt="" />
                        <div className="pt-6 md:p-8 text-center md:text-left space-y-4">
                            <figcaption className="font-medium justify-center">
                                <div className="name-section">
                                    PREETI MAHAPATRA
                                </div>
                                <div className="header-designantion-home">
                                    FOUNDER & MENTOR
                                </div>
                            </figcaption>
                            <blockquote>
                                <p className="home-page-para text-[15px] font-medium text-justify">
                                    As the founder and mentor of Mahapatra Universal Limited, Mrs. Mahapatra has orchestrated strategic investments across diverse sectors including Engineering, Information Technology, Sports & Media, Hospitality, Solar Power and Renewable Energy, Gems & Jewellery, and Mining. Under her stewardship, the group consolidated and  acquired six companies into a single division, surpassing competitors, securing a greater market share, and boosting earnings. Notably, she led the charge in championing two additional acquisitions while implementing operational restructuring to drive maximum cost efficiency and building  profits for organization.
                                </p>
                            </blockquote>
                        </div>
                    </figure>


                    {/* Our Brands */}
                    <div className="brand-container-data-wrapper grid grid-cols-1 md:grid-cols-4 mt-5 gap-4">
                        <div className="brand-container-card">
                            <img src={sceniuz} alt="" srcset="" className='brand-images' />
                            <p className="card-our-brand-container text-center">A certified Qlik & Microsoft Partners specialized inData Analytics,Data Engineering, and Data Advisory.</p>
                        </div>

                        <div className="brand-container-card">
                            <img src={Futura} alt="" srcset="" className='brand-images' />
                            <p className="card-our-brand-container text-center">Portfolio of hotel investment across various international brands exceeding global standards, ultimately enhancing both its profitability & valuation  by creating a benchmark in  industry.</p>
                        </div>

                        <div className="brand-container-card">
                            <img src={Preetis} alt="" srcset="" className='brand-images' />
                            <p className="card-our-brand-container text-center">Preeti’s is a one-stop retail chain of stores that aims to offer customers a wide range of basic home and personal products under one roof. </p>
                        </div>

                        <div className="brand-container-card">
                            <img src={HappyHrs} alt="" srcset="" className='brand-images' />
                            <p className="card-our-brand-container text-center">HAPPY HOURS is a 'Fun Mobile App' on mobile and web for all promotional offers related to food, drinks, bars, movies,adventures and more.</p>
                        </div>

                    </div>


                    {/* About Us Section */}
                    <About/>


                    {/* Contact Us */}
                    <Contact/>


                

                </div>

                <Footer/>
            </section>
        </>
    )
}

export default Home
